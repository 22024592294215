import {useEffect, useState, useCallback} from 'react';
import {Map as PigeonMap, Draggable} from 'pigeon-maps';
import {Box, Typography} from '@mui/material';
import {Simulation} from '../types/Simulation';
import SimulationList from './SimulationList';
import CreateSimulationModal from './CreateSimulationModal';
import AircraftIcon from './AircraftIcon';
import {useKeycloak} from "@react-keycloak/web";

export default function Map() {
    const defaultLocation: [number, number] = [50.073924, 14.466632];
    const [simulations, setSimulations] = useState<Simulation[]>([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [clickedLocation, setClickedLocation] = useState<{ x: number; y: number } | null>(null);
    const {keycloak} = useKeycloak();

    const fetchAllSimulationsState = useCallback(async () => {
        if (!keycloak.token) return;
        try {
            const response = await fetch('/api/v1/simulations/', {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`
                }
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setSimulations(data);
        } catch (error) {
            console.error('Error fetching simulations state:', error);
        }
    }, [keycloak.token]);

    useEffect(() => {
        if (keycloak.authenticated) {
            fetchAllSimulationsState();
        }

        const intervalId = window.setInterval(fetchAllSimulationsState, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [keycloak.authenticated, fetchAllSimulationsState]);

    const handleMapClick = (event: { latLng: [number, number] }) => {
        if (!keycloak.authenticated) {
            alert('Please log in to create a new simulation.');
            return;
        }

        const clickedLocation = {
            x: event.latLng[0],
            y: event.latLng[1],
        };
        setClickedLocation(clickedLocation);
        setCreateModalOpen(true);
    };

    function cartoTiles(x: number, y: number, z: number, dpr?: number) {
        return `https://cartodb-basemaps-4.global.ssl.fastly.net/light_all/${z}/${x}/${y}${(dpr ?? 1) >= 2 ? '@2x' : ''}.png`;
    }

    return (
        <Box sx={{position: 'relative', height: '100vh', width: '100vw'}}>
            <PigeonMap
                defaultCenter={defaultLocation}
                defaultZoom={14}
                provider={cartoTiles}
                onClick={handleMapClick}
                width={window.innerWidth}
                height={window.innerHeight}
            >
                {simulations.map((simulation) => (
                    <Draggable
                        key={simulation.id}
                        anchor={[simulation.state.location.latitude, simulation.state.location.longitude]}
                        offset={[12, 12]}
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Typography
                                variant="caption"
                                sx={{mb: 1, backgroundColor: 'white', padding: '2px 4px', borderRadius: '4px'}}
                            >
                                {simulation.configuration.uas_id}
                            </Typography>
                            <AircraftIcon
                                selected={simulation.configuration.paused}
                            />
                        </Box>
                    </Draggable>
                ))}
            </PigeonMap>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '300px',
                    maxHeight: '100%',
                    overflowY: 'auto',
                    bgcolor: 'rgba(255, 255, 255, 0.8)',
                    boxShadow: 3,
                    zIndex: 1000,
                }}
            >
                <Typography variant="h6" sx={{p: 2, borderBottom: '1px solid #ddd'}}>
                    Simulations
                </Typography>
                <SimulationList simulations={simulations} setSimulations={setSimulations}/>
            </Box>
            {clickedLocation && (
                <CreateSimulationModal
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    initialLocation={clickedLocation}
                />
            )}
        </Box>
    );
}
