import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    TextField,
    Button,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Slider,
    FormHelperText
} from '@mui/material';
import { useKeycloak } from "@react-keycloak/web";

interface Props {
    open: boolean;
    onClose: () => void;
    initialLocation: { x: number; y: number };
}

const CreateSimulationModal: React.FC<Props> = ({ open, onClose, initialLocation }) => {
    const [deviceTypes, setDeviceTypes] = useState<{ [key: string]: string }>({});
    const [flightPaths, setFlightPaths] = useState<{ [key: string]: string }>({});
    const [uasIdList, setUasIdList] = useState<string[]>([]);
    const [selectedDeviceType, setSelectedDeviceType] = useState('');
    const [selectedFlightPath, setSelectedFlightPath] = useState('');
    const [uasId, setUasId] = useState('');
    const [uasIdError, setUasIdError] = useState(false);
    const [initialAltitude, setInitialAltitude] = useState(0);
    const [intervalSim, setIntervalSim] = useState(500);
    const [latitude, setLatitude] = useState(initialLocation.x);
    const [longitude, setLongitude] = useState(initialLocation.y);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { keycloak } = useKeycloak();

    useEffect(() => {
        const fetchEnums = async () => {
            try {
                const response = await fetch('/api/v1/enums/', {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`
                    }
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setDeviceTypes(data.DeviceType);
                setFlightPaths(data.FlightPath);

                if (Object.keys(data.DeviceType).length > 0) {
                    setSelectedDeviceType(Object.keys(data.DeviceType)[0]);
                }
                if (Object.keys(data.FlightPath).length > 0) {
                    setSelectedFlightPath(Object.keys(data.FlightPath)[0]);
                }
            } catch (error) {
                console.error('Error fetching enums:', error);
            }
        };

        fetchEnums();
    }, [keycloak.token]);

    useEffect(() => {
        const fetchAllowedUasIds = async () => {
            try {
                const response = await fetch('/api/v1/uas/available-uasids/', {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                    },
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const serialNumbers = await response.json();
                setUasIdList(serialNumbers);
            } catch (error) {
                console.error('Error fetching UAS IDs:', error);
            }
        };

        if (open) {
            fetchAllowedUasIds();
        }
    }, [keycloak.token, open]);

    const handleSubmit = async () => {
        if (!uasId) {
            setUasIdError(true);
            return;
        }

        const payload = {
            uas_id: uasId,
            scenario: {
                origin_location: {
                    latitude,
                    longitude,
                    altitude: initialAltitude,
                },
                flight_path: selectedFlightPath,
            },
            interval_sim: intervalSim,
            paused: false,
            device: selectedDeviceType,
        };

        try {
            const response = await fetch('/api/v1/simulations/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${keycloak.token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                if (response.status === 404) {
                    setErrorMessage('Device not found');
                } else if (response.status === 422) {
                    setErrorMessage('Invalid data');
                } else {
                    setErrorMessage('An error occurred. Please try again.');
                }
                return;
            }

            const result = await response.json();

            // Reset form state
            setUasId('');
            setInitialAltitude(0);
            setIntervalSim(500);
            setLatitude(initialLocation.x);
            setLongitude(initialLocation.y);

            if (Object.keys(deviceTypes).length > 0) {
                setSelectedDeviceType(Object.keys(deviceTypes)[0]);
            }
            if (Object.keys(flightPaths).length > 0) {
                setSelectedFlightPath(Object.keys(flightPaths)[0]);
            }

            onClose();
            setErrorMessage(null); // Clear any error messages on success
        } catch (error) {
            setErrorMessage('An error occurred while creating the simulation. Please try again.');
            console.error('Error creating simulation:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    p: 4,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 3,
                    maxWidth: 400,
                    margin: 'auto',
                    mt: '10%',
                }}
            >
                <Typography variant="h6" gutterBottom>Create Simulation</Typography>
                <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="device-type-label">Device Type</InputLabel>
                    <Select
                        labelId="device-type-label"
                        value={selectedDeviceType}
                        onChange={(e) => setSelectedDeviceType(e.target.value)}
                        label="Device Type"
                        variant='outlined'
                    >
                        {Object.entries(deviceTypes).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="flight-path-label">Flight Path</InputLabel>
                    <Select
                        labelId="flight-path-label"
                        value={selectedFlightPath}
                        onChange={(e) => setSelectedFlightPath(e.target.value)}
                        label="Flight Path"
                        variant='outlined'
                    >
                        {Object.entries(flightPaths).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal" variant="outlined" error={uasIdError}>
                    <InputLabel id="uas-id-label">UAS ID</InputLabel>
                    <Select
                        labelId="uas-id-label"
                        value={uasId}
                        onChange={(e) => {
                            setUasId(e.target.value);
                            setUasIdError(false);
                        }}
                        label="UAS ID"
                        variant="outlined"
                        required
                    >
                        {uasIdList.map((id) => (
                            <MenuItem key={id} value={id}>
                                {id}
                            </MenuItem>
                        ))}
                    </Select>
                    {uasIdError && <FormHelperText>UAS ID is required</FormHelperText>}
                </FormControl>

                <TextField
                    label="Initial Altitude"
                    type="number"
                    value={initialAltitude}
                    onChange={(e) => setInitialAltitude(Number(e.target.value))}
                    fullWidth
                    margin="normal"
                />
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TextField
                        label="Latitude"
                        type="number"
                        value={latitude}
                        onChange={(e) => setLatitude(Number(e.target.value))}
                        fullWidth
                    />
                    <TextField
                        label="Longitude"
                        type="number"
                        value={longitude}
                        onChange={(e) => setLongitude(Number(e.target.value))}
                        fullWidth
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        label="Simulation Interval (ms)"
                        type="number"
                        value={intervalSim}
                        onChange={(e) => setIntervalSim(Number(e.target.value))}
                        sx={{ width: 100 }}
                    />
                    <Slider
                        value={intervalSim}
                        min={200}
                        max={3000}
                        step={25}
                        onChange={(e, newValue) => setIntervalSim(newValue as number)}
                        valueLabelDisplay="auto"
                        sx={{ flexGrow: 1, ml: 2 }}
                    />
                </Box>

                {errorMessage && (
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={handleSubmit} color="primary" variant="contained">Create</Button>
                    <Button onClick={onClose} color="secondary" variant="outlined">Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateSimulationModal;
